<template>
  <div class="contact d-flex flex-column align-items-center">
    <base-header
      v-if="$device.isMobile"
      :title="$t('刪除帳號')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
    ></base-header>
    <div class="contact-container p-3 p-md-0">
      <div class="contact-wrapper w-100">
        <div class="qrcode-wrapper">
          <div class="img-wrapper p-1">
            <img class="w-100" :src="qrCodeImage" />
          </div>
        </div>
        <div
          class="text-wrapper w-100 d-flex flex-column align-items-center p-3"
        >
          <div
            class="upper-text w-100 d-flex align-items-center justify-content-center"
          >
            {{ $t('點選下列網址或掃描QRCode') }}
          </div>
          <div
            class="lower-text w-100 d-flex align-items-center justify-content-center text-center"
          >
            {{ $t('聯繫［黑嘉嘉圍棋教室］LINE 客服刪除帳號') }}
          </div>
          <a
            href="https://line.me/R/ti/p/%40753mueeo"
            target="_blank"
            class="join-line d-flex align-items-center justify-content-center mt-3"
            >{{ $t('加入LINE@聯繫客服') }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import {toDataURL} from 'qrcode';
import {manifest} from '@/constant/env';

export default {
  components: {
    BaseHeader,
  },
  data() {
    return {
      toDataURL,
      qrCodeImage: '',
    };
  },
  created() {
    return this.generateQrCode();
  },
  methods: {
    async generateQrCode() {
      this.qrCodeImage = await this.toDataURL(manifest.contactUrl, {
        errorCorrectionLevel: 'L',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  width: 100%;
  height: 100%;
  overflow: overlay;
  @media screen and (min-width: 768px) {
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 2px 7px 0px rgba(199, 199, 199, 0.3);
  }
}
.contact-container {
  width: 100%;
  .contact-wrapper {
    height: calc(100vw - 32px + 140px);
    box-shadow: 0px 2px 7px 0px rgba(199, 199, 199, 0.3);
    background-color: #ffffff;
    border-radius: 10px;
    @media screen and (min-width: 768px) {
      height: auto;
      border-radius: 10px;
      padding-top: 24px;
    }
    .title {
      @media screen and (min-width: 768px) {
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        color: $font-grayscale-1;
        padding: 16px 6px 16px 16px;
        border-bottom: 1px solid $line-2;
        .icon-X {
          font-size: 32px;
          padding: 0;
          color: $primary;
          cursor: pointer;
        }
      }
    }
    .qrcode-wrapper {
      height: calc(100vw - 32px);
      width: 100%;
      padding: 16px;
      border-bottom: 1px solid $line-2;
      margin: 0 auto;
      @media screen and (min-width: 768px) {
        width: 300px;
        height: auto;
        border: 1px solid $line-2;
      }
      .img-wrapper {
        border: solid 1px $line-2;
        border-radius: 10px;
        overflow: hidden;
        @media screen and (min-width: 768px) {
          border: none;
        }
        img {
          transform: scale(1.2);
        }
      }
    }
    .text-wrapper {
      height: 140px;
      .upper-text,
      .lower-text {
        height: 28px;
        @media screen and (min-width: 768px) {
          height: auto;
        }
      }
      .join-line {
        height: 28px;
        text-decoration: underline;
      }
    }
  }
}
</style>
